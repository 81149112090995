.referral-promo-card {
    overflow: hidden;
    margin-bottom: 20px;
    .referral-promo-header {
        background-color: #134580;
        .hero-title {
            padding: 0px 40px 20px;
            text-align: center;
            @media (min-width: 768px) {
                padding: 0px 0px 0px 40px;
                text-align: left;
            }

            h1 {
                font-weight: 800;
                font-size: 36px;
                color: white;
            }
            h3 {
                font-weight: 800;
                font-size: 24px;
                color: white;
            }
            span {
                color: #67c8c6;
            }
        }
        .hero-image {
            max-height: 270px;
            max-width: 100%;
            padding: 25px;
        }
    }
    .referral-promo-body {
        margin: 30px 0;

        .shareable-label {
            color: #134580;
            font-family: Gotham;
            font-weight: bold;
            font-size: 20px;
            margin: 0px;
            padding: 16px;
        }
        .modal-form-link {
            color: #134580;
            font-family: Gotham;
            font-size: 14px;
            margin: 0px;
            padding: 16px;
            .modal-reveal {
                color: #cd0084;
                text-decoration: underline;
                cursor: pointer;
                // font-weight: bold;
            }
        }
        .referral-promo-shareables {
            margin: 0 20px;
            .code-pill {
                width: 300px;
                border-radius: 900px;
                background: #e4edf7;
                color: #134580;
                font-family: Gotham;
                font-style: normal;
                font-weight: bold;
                overflow: hidden;
                margin: 5px;
                .code-pill-left {
                    font-size: 16px;
                    padding: 15px 30px;
                    width: max-content;
                    text-transform: uppercase;
                }
                .code-pill-right {
                    position: relative;
                    background: #cd0084;
                    color: white;
                    &:hover {
                        background: darken($color: #cd0084, $amount: 5%);
                    }

                    .btn-code-pill-btn {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0px;
                        font-size: 14px;
                        width: 100%;
                        text-align: center;
                        color: white;
                        .react-icons {
                            vertical-align: middle !important;
                        }
                    }
                }
            }
            .btn-social-circle {
                margin: 5px 10px 5px 0px;
                width: 50px;
                height: 50px;
                border-radius: 900px;
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: white;
                }
            }
        }

        .shareable-divider {
            border: 1px solid #d5d5d5;
            margin: 0 16px;
        }

        .refer-promo-title {
            margin: 30px 0 20px;
            font-weight: 500;
            font-size: 14px;
        }
        .refer-promo-body {
            margin-bottom: 45px;
            font-size: 12px;
        }
    }
}
