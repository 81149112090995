.navbar {
  .navbar-brand {
      @media(min-width: 1200px) {
          padding: 0;
      }
  }
  .nav-link {
    font-size: 13px;
    font-family: Gotham;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: -0.25px;
  }

  .nav-link.active {
    position: relative;
    background-color: map-get($theme-colors,'primary-dark-3');
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;

    @media(min-width: 1200px) {
        &:after {
            content: '';
            position: absolute;
            border-radius: 2px;
            background-color: map-get($theme-colors, 'primary');
            border: 1.5px solid map-get($theme-colors, 'primary');
            bottom: 1.5rem;
            width: 70%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
  }

  .navbar-collapse {
      @media(max-width: 1199px) {
          background-color: map-get($theme-colors, 'primary-dark-1');
          margin: 0 -1rem;
          padding: 0.5rem 1rem;
      }
  }
}
