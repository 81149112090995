.start-claim-page {
    .steps-holders {
        .step {
            position: relative;
            @media(max-width: 767px) {
                margin-bottom: 80px;
            }
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                background-color: #D6D5E7;
                @media(max-width: 767px) {
                    margin-top: 10px;
                    top: 100%;
                    left: 50%;
                    width: 3px;
                    height: 50px;
                }
                @media(min-width: 768px)and(max-width: 991px) {
                    top: 70px;
                    left: 100%;
                    width: 62px;
                    height: 3px;
                }
                @media(min-width: 992px) {
                    top: 70px;
                    left: 85%;
                    width: 148px;
                    height: 3px;
                }
                @media(min-width: 1200px) {
                    top: 70px;
                    left: 85%;
                    width: 167px;
                    height: 3px;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }

            .icon-holder {
                text-align: center;
                margin: 0 auto;
                display: flex;
                align-items: center;
                width: 143px;
                height: 143px;
                background-color: #FFFFFF;
                border-radius: 50%;
                color: map-get($theme-colors, 'primary');
                svg {
                    margin: 0 auto;
                }
            }
            .title-holder {
                padding-top: 20px;
                font-weight: 600;
                font-size: 26px;
                color: map-get($theme-colors, 'primary-dark-1');
                line-height: 30px;
            }
            .description-holder {
                padding-top: 18px;
                font-size: 14px;
            }
        }
    }
}

.claim-new-page {
    .accordion {
        padding-bottom: 12px;
        .card {
            border-radius: 0px;
            border: 0;
            box-shadow: none;
            .card-header {
                background-color: map-get($theme-colors, 'primary-dark-1');
                color: #FFFFFF;
                font-size: 21px;
                font-weight: 500;
                position: relative;
                &:hover {
                    cursor: pointer;
                }
                .num {
                    vertical-align: middle;
                    background-color: map-get($theme-colors, 'primary');
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 28px;
                    margin-right: 10px;
                }
                .chevron {
                    position: absolute;
                    right: 20px;
                    svg {
                        vertical-align: middle !important;
                    }
                }
            }
        }
    }
    .claim-details-section {
        table {
            thead {
                box-shadow: inset 0 -2px 0 0 #e5e4ea;
            }
        }
    }
    .payment-details-section {

    }
    .finalise-claim-section {
        ul {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 0px;
            list-style: none;
            font-size: 14px;
            margin-bottom: 0px;
            li {
                padding-left: 10px;
                &:before {
                    content: "-";
                    display: inline-block;
                    position: relative;
                    left: -5px;
                }
            }
        }
    }
}

.thankyou-claim-page {
    .finish-btn {
        padding: 13.5px 78px;
        border-color: #D0CFDD;
        border-width: 2px;
        &:hover {
            background-color: transparent;
            opacity: 0.7;
        }
    }
    &.error-side {
        table {
            text-align: left;
            border: 0px;
            thead {
                box-shadow: none;
                th {
                    border: 0px;
                    box-shadow: inset 0px -2px 0px 0px #E5E4EA;
                }
            }
            tbody {
                tr {
                    box-shadow: inset 0px -1px 0px 0px #E5E4EA;
                    &:nth-of-type(odd) {
                        background-color: #FFFFFF;
                    }
                    &:nth-of-type(even) {
                        background-color: #FAFAFA;
                    }
                    td {
                        font-size: 14px;
                        &:nth-of-type(5n) {
                            color: #429F33;
                        }
                    }
                }
            }
            tfoot {
                tr {
                    td {
                        font-size: 16px;
                        font-weight: 500;
                        &:nth-of-type(5n) {
                            color: #429F33;
                        }
                    }
                }
            }
        }
    }
}
