.modal-dialog-align-right {
    margin: 0 0 0 auto;
    min-height: 100vh;
    .modal-content {
        min-height: 100vh;
    }
    .modal-content-align-right {
        // min-height: 100vh;
    }
}

.modal {
    .modal-dialog {
        .modal-content {
            border: 0;
            .modal-body {
                padding: 15px;
            }
        }
    }
}

.hra-modal {
    .modal-dialog {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        height: 100vh;
        padding: 30px;
        .modal-content {
            height: 100%;
            .modal-header {
                border-bottom: 0;
            }
            .modal-body {
                height: 100%;
                padding: 0px;
                overflow: hidden;
                .inner {
                    overflow-y: auto;
                    height: 100%;
                    padding: 20px;
                    @media (min-width: 992px) {
                        padding: 60px;
                    }
                }
                iframe {
                    border: 0;
                }
            }
        }
    }
}

.modal-box-shadow {
    box-shadow: inset 0px -2px 0px 0px #e6e6ee;
}

.modal-pane {
    &-active {
        text-align: center;
        background: #fff;
        color: #cd0084;
        font-weight: 500;
        font-size: 13px;
    }
    &-inactive {
        text-align: center;
        background: transparent;
        color: #25282a;
        font-weight: 500;
        font-size: 13px;
    }
    &-background {
        background-color: #efeff5;
        padding-top: 2px;
        box-shadow: inset 0px 2px 0px #e5e4ea;
    }
}

.modal-tab {
    padding: 15px;
    cursor: pointer;
    color: #464b4e;
    font-size: 13px;
}

.tab-frame {
    padding-top: 2px;
    margin-top: 20px;
}

.modal-submit {
    padding: 9px;
    margin-right: 10px;
}

.limit-modal {
    .modal-dialog {
        max-width: none;
        width: 900px;
        .modal-content {
            .modal-header {
                background-color: transparent !important;
                border-bottom: 0px;
                padding: 0px;
                button {
                    background-color: transparent !important;
                    color: #89889b;
                    padding: 30px 30px 5px 5px;
                    svg {
                        color: #89889b !important;
                    }
                    &:hover {
                        svg {
                            color: #134580 !important;
                        }
                    }
                }
            }
            .modal-body {
                padding: 15px 115px 100px;
                .heading {
                    align-items: center;
                    .title {
                        font-weight: bold;
                        font-size: 36px;
                        color: #333333;
                    }
                }
                .secondlevel {
                    & > div {
                        margin-top: 40px;
                        width: 50%;
                    }
                    .top {
                        font-size: 14px;
                    }
                    .bottom {
                        font-weight: bold;
                        font-size: 26px;
                        color: #333333;
                    }
                }
                .limit-table {
                    table {
                        width: 100%;
                        margin-bottom: 0.5em;
                        font-size: 1em;
                        border-collapse: initial;
                        border-spacing: 0;
                        border: 0;
                        margin: 0;
                        padding-top: 40px;
                        thead {
                            tr {
                                background-color: #67c8c6;
                                border-top-left-radius: 4px;
                                border-top-right-radius: 4px;
                                th {
                                    border: 2px solid #67c8c6;
                                    padding: 10px;
                                    font-weight: 800;
                                    font-size: 13px;
                                    color: #ffffff;
                                    &:first-child {
                                        border-top-left-radius: 4px;
                                        padding-left: 20px;
                                    }
                                    &:last-child {
                                        border-top-right-radius: 4px;
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                box-sizing: border-box;
                                margin: 0;
                                padding: 0;
                                border: 0;
                                vertical-align: baseline;
                                text-align: left;
                                td {
                                    padding: 5px 10px;
                                    &:first-child {
                                        border-left: 2px solid #e5e4ed;
                                        padding: 5px 10px 5px 5px;
                                    }
                                    &:last-child {
                                        border-right: 2px solid #e5e4ed;
                                    }
                                    svg {
                                        color: #67c8c6;
                                    }
                                }
                                &:last-child {
                                    td {
                                        border-bottom: 2px solid #e5e4ed;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
