form {
  .form-label {
    font-weight: 700;
    letter-spacing: 0.55px;
    margin-left: 2px;
  }

  input {
    color: #464b4e;
  }

  label,
  input {
    font-family: Gotham;
  }

  input[type='radio'] {
    appearance: none;
    background: #FFFFFF;
    width: 16px;
    height: 16px;
    border: 1px solid #B7B7C0;
    border-radius: 40px;
    outline: none;
    vertical-align: top;
    margin-top: 0px;
    &:checked {
        border: 5px solid #67C8C6;
    }
  }

  input[type='checkbox'] {
    appearance: none;
    background: #FFFFFF;
    border: 1px solid #B7B7C0;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    margin-top: 0px;
    outline: none;
    &:checked {
        border: 1px solid #67C8C6;
        background: #67C8C6;
        &:after{
            text-align: center;
            display: inline-block;
            content:'\2713';
            width: 16px;
            height: 16px;
            color: #FFFFFF;
            line-height: 16px;
        }
    }
  }

  .react-datepicker-wrapper {
      display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**/
