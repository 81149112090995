.membership-messages {
    margin-bottom: 24px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 6px;
    .error {
        background: #FCE3E6;
        padding: 5px;
        border-radius: 12px;
        font-size: 14px;
        svg {
            color: #E84256;
        }
        a {
            color: #CD0084;
            &:hover {
                color: #CD0084;
                opacity: 0.7;
            }
        }
    }
    &>div + div {
        margin-top: 6px;
    }
}
