// Render fonts correctly
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.line-height-1 {
    line-height: 1;
}

.font-feature {
    font-family: Gotham, serif;
}

// Font Faces
@include font-face(Gotham, '../fonts/Gotham-Medium', null, otf);
@include font-face(Gotham, '../fonts/Gotham-Thin', 100, otf);
@include font-face(Gotham, '../fonts/Gotham-Light', 300, otf);
@include font-face(Gotham, '../fonts/Gotham-Book', 400, otf);
@include font-face(Gotham, '../fonts/Gotham-Medium', 500, otf);
@include font-face(Gotham, '../fonts/Gotham-Bold', 700, otf);
@include font-face(Gotham, '../fonts/Gotham-Black', 800, otf);
@include font-face(Gotham, '../fonts/Gotham-Ultra', 900, otf);

// HTML Components
h1 {
    color: map-get($theme-colors, 'primary-dark-1');
    font-size: 21px;
    font-family: Gotham, serif;
    font-weight: 500;
}

p,
ul {
    color: #464b4e;
    font-family: Gotham, serif;
}

a {
    color: map-get($theme-colors, 'primary-dark-1');
    font-weight: bold;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: map-get($theme-colors, 'primary-dark-1');
        opacity: 0.8;
    }

    &.btn,
    &.nav-link {
        text-decoration: none;
    }
}

.link-none {
    text-decoration: none;
    font-weight: normal;
}

// All other font weights (regular, bold, normal, light are already available from Bootstrap. Don't write them here!
.font-weight-thin {
    font-weight: 100;
}

.font-weight-normal {
    font-weight: 400;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-black {
    font-weight: 800;
}

.font-weight-ultra {
    font-weight: 900;
}

.font-weight-bold {
    font-weight: 700;
}

.font-weight-semibold {
    font-weight: 600;
}

.font {
    // font sizes
    &-20 {
        font-size: 20px;
    }

    &-18 {
        font-size: 18px;
    }

    &-16 {
        font-size: 16px;
    }

    &-14 {
        font-size: 14px;
    }

    &-13 {
        font-size: 13px;
    }

    &-12 {
        font-size: 12px;
    }

    // accent colours
    &-faded {
        color: #9998a8;
    }
}
