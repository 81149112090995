.forgot-password-page {
    .text {
        padding: 10px 10px 10px 60px;
        p{
            font-size: 32px;
            font-weight: 900;
            line-height: 34px;
            color: #FFFFFF;
            strong {
                font-weight: 900;
                color: map-get($theme-colors, 'primary-dark-1');
            }
        }
    }
}
