.wellbeing-page {
    h1 {
        color: map-get($theme-colors, 'primary');
        font-size: 46px;
        text-align: center;
        font-weight: 900;
    }
    h2 {
        color: map-get($theme-colors, 'primary-dark-1');
        font-size: 22px;
        text-align: center;
        font-weight: normal;
    }

    .item {
        padding-top: 86px;
        padding-bottom: 55px;
        .inner {
            background-color: #FFFFFF;
            height: 100%;
            border-radius: 12px;
            padding: 20px;
            .display-image {
                text-align: center;
                img {
                    width: 240px;
                    height: 240px;
                    margin-top: -106px;
                }
            }
            .title {
                h3 {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 26px;
                    text-align: center;
                    color: map-get($theme-colors, 'primary-dark-1');
                }
            }
            .body {
                font-size: 14px;
            }
            .logo {
                display: none;
                text-align: center;
                img {

                }
            }
        }
    }
}
