.suspend-modal-cover {
    .modal-dialog {
        .modal-content {
            .modal-body,
            .modal-header {
                padding: 20px;
            }
        }
    }

    p.form-heading {
        font-weight: 500;
        color: #25282a;
    }

    .heading-text {
        //border-bottom: 2px solid #979797;
    }

    p {
        font-size: 14px;
        line-height: 19px;
    }

    .disclaimer-row {
        p {
            font-size: 12px;

            a {
                font-weight: bold;
                color: #464b4e;
                text-decoration: underline;
            }
        }

        label {
            padding-bottom: 0 !important;
        }

        .checkbox-group {
            margin-bottom: 10px;
        }
    }

    .frm-brdr {
        box-shadow: inset 0px 2px 0px #e6e6ee;
        padding-top: 10px;
    }

    form {
        .form-group.checkbox-group {
            position: relative;

            input[type='checkbox'] {
                display: inline-block;
                position: absolute;
                top: 0;

                + label {
                    position: relative;
                    margin: 0;
                    top: -5px;
                    left: 25px;
                    max-width: calc(100% - 25px);
                }
            }
        }

        .form-group {
            label {
                font-weight: 500;
                font-size: 13px;
                color: #464b4e;
            }
        }

        .members-travelling-overseas {
            p {
                margin-bottom: 22.5px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
            }

            .members-travelling-overseas--list {
                .checkbox-group {
                    margin-bottom: 3px;
                }
            }

            .partner-cover-check {
                margin-top: 40px;

                label {
                    font-weight: normal;
                }

                input[type='checkbox'] {
                    top: -3px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .modal-dialog {
            max-width: 600px;
        }
    }

    @media (max-width: 575px) {
        padding-left: 0 !important;
    }
}
