.overview-ad-block {
    .text {
        padding: 30px 10px 0px 60px;
        p {
            font-size: 29px;
            font-weight: 900;
            line-height: 32px;
            color: #ffffff;
            strong {
                font-weight: 900;
                color: map-get($theme-colors, 'primary-dark-1');
            }
        }
        &.promo {
            p {
                strong {
                    color: map-get($theme-colors, 'white') !important;
                }
            }
            small {
                color: map-get($theme-colors, 'white') !important;
                font-weight: 600;
                font-size: small;
            }
        }
        p + p {
            font-size: 18px;
        }
    }
    .text-subtitle {
        padding: 20 0 0 20;
        p {
            font-size: 21px;
            font-weight: 900;
            line-height: 32px;
            color: #ffffff;
            strong {
                font-weight: 900;
                color: map-get($theme-colors, 'primary-dark-1');
            }
        }
        p + p {
            font-size: 18px;
        }
    }
    .button-holder {
        margin-top: 20px;
        padding: 0px 0px 20px 60px;
    }

    @media (max-width: 767px) {
        .text {
            padding: 10px 10px 0px 10px;
            p {
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
            }
        }
        
        .button-holder {
            padding: 0px 0px 10px 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .text-subtitle {
                padding: 20 0 0 20;
                font-size: 12px;
                line-height: 20px;
                font-weight: 400;
                color:#ffffff
            }
        }
    }
}

.ad-container {
    .overview-ad-block:not(:first-child) {
        margin-top: 10px;
    }
}
