$body-bg: #e4edf7;

// Navbar
$navbar-dark-color: #fff;
$navbar-nav-link-padding-x: 0.75rem;

// Default buttons styles
$btn-font-weight: 700;
$btn-font-family: Gotham;
$input-btn-font-size: 14px;
$input-btn-padding-y: 0.5rem;
$btn-border-radius: 6px;

// Override theme colors
$theme-colors: (
    'primary': #67c8c6,
    'primary-light': #c7e1ff,
    'primary-dark-1': #134580,
    'primary-dark-2': #06294c,
    'primary-dark-3': #09366b,
    'tertiary': #a9d500,
    'secondary': #cd0084,
    'danger': #ff4136,
    'turquoise': #1aa5bd,
    'light-turquoise': #87eae8,
    'body': #e4edf7,
    'light-gray': #89889b,
    'mid-gray': #d1dbe7,
    'body-gray': #e4edf7,
    'black': #25282a,
    'purple': #8246af,
    'light-purple': #e3e3f8,
    'label-gray': #464b4e,
    'label-gray-no': #9998a8,
    'white': #ffffff,
);

// Custom Modal animation
$modal-fade-transform: translateX(5vw);

$label-margin-bottom: 3px;

@import '~bootstrap/scss/bootstrap.scss';

// Utils
@import './mixins';

// Custom Components
@import './index.scss';
@import './buttons.scss';
@import './header.scss';
@import './font.scss';
@import './table.scss';
@import './tabs.scss';
@import './form.scss';
@import './modal.scss';
@import './userswitcher.scss';
@import './autocomplete.scss';
@import './cursors.scss';
@import './provider.scss';
@import './claimPages.scss';
@import './overviewAdBlock.scss';
@import './loginPage.scss';
@import './registerPage.scss';
@import './forgotPasswordPage.scss';
@import './coverChange.scss';
@import './coverPage.scss';
@import './Components/SuspendCoverModal.scss';
@import './Components/MakeAContributionModal.scss';
@import './Components/ClaimDetailsModal.scss';
@import './MembershipMessages.scss';
@import './wellbeingpage.scss';
@import './rebateForm.scss';
