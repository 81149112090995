.provider-autocomplete{
    position: absolute;
    z-index: 1;
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
    & > div {
        font-size: 14px;
        padding: 5px;
        box-shadow: inset 0 -2px 0 0 #e5e4ea;
        &:last-child{
            box-shadow: none;
        }
        &:hover{
            background: #E6E6EE;
            cursor: pointer;
        }
        .top{
            color: map-get($theme-colors, 'black');
            font-weight: 500;
        }
        .bottom{
            font-size: 11px;
        }
    }
}
