.claim-status-badge {
    display: inline-block;
    font-size: 12px;
    color: white;
    background-color: #4a90e2;
    border-radius: 44px;
    padding: 3px 10px;
}

.claim-status-badge,
.paid {
    background-color: #429f33;
    min-width: 96px;
}
