.nav-tabs {
  .nav-link {
    border: 0;
    color: #464b4e;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }

  .nav-link.active {
    color: map-get($theme-colors, 'secondary');
    border-bottom: 3px solid map-get($theme-colors, 'secondary');
  }
}
